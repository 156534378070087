dmx.Component('pouchdb-detail', {

  initialData: {
    data: {},
    stale: false,
  },

  attributes: {
    db: {
      type: String,
      default: null,
    },

    docid: {
      type: String,
      default: null,
    },

    attachments: {
      type: Boolean,
      default: false,
    },

    autoupdate: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    select (docid) {
      this.props.docid = docid;
    },

    refresh () {
      this._getDoc(this.props.docid);
    },
  },

  events: {
    change: Event,
    error: Event,
  },

  init () {
    if (this.props.db) {
      this._initDatabase();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('db')) {
      this._initDatabase();
    } else {
      this._getDoc();
    }
  },

  destroy () {
    this._cancelChanges();
  },

  _initDatabase () {
    this._cancelChanges();

    if (!this.props.db) {
      this._db = null;
      return;
    }

    this._db = dmx.pouchdb.get(this.props.db);

    if (this.props.docid) {
      this._getDoc();
    }
  },

  _getDoc () {
    this._cancelChanges();

    if (!this._db) return;

    if (this.props.docid) {
      this._db.get(this.props.docid, { attachments: this.props.attachments }).then((doc) => {
        this.set({
          data: doc,
          stale: false,
        });
      });  

      this._changes = this._db.changes({
        live: true,
        include_docs: true,
        attachments: this.props.attachments,
        since: 'now',
        doc_ids: [this.props.docid],
      }).on('change', (change) => {
        if (dmx.debug) console.debug(`${this.name}:changes:change`, change);
        if (this.props.autoupdate) {
          this.set({
            data: change.doc,
            stale: false,
          });
        } else {
          this.set('stale', true);
        }
        this.dispatchEvent('change', null, change);
      }).on('error', (err) => {
        if (dmx.debug) console.debug(`${this.name}:changes:error`, err);
        this.set({
          data: {},
          stale: true,
        });
        this.dispatchEvent('error', null, err);
      });
    } else {
      this.set({
        data: {},
        stale: false,
      });
    }
  },

  _cancelChanges () {
    if (this._changes) {
      if (dmx.debug) {
        console.log('cancel changes', this._changes);
      }
      
      this._changes.cancel();
      this._changes = null;
    }
  },

});
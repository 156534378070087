dmx.Component('pouchdb', {

  attributes: {
    db: {
      type: String,
      default: null,
    },
  },

  methods: {
    replicateFrom (remote) {
      return this._db.replicate.from(dmx.pouchdb.get(remote));
    },

    replicateTo (remote) {
      return this._db.replicate.to(dmx.pouchdb.get(remote));
    },
  },

  events: {
    change: Event,
    error: Event,
    sync: Event,
  },

  init () {
    document.addEventListener('pouchdb:sync', (event) => {
      if (event.detail.database === this.props.db) {
        this.dispatchEvent('sync', null, event.detail.change);
      }
    });

    document.addEventListener('pouchdb:error', (event) => {
      if (event.detail.database === this.props.db) {
        this.dispatchEvent('error', null, event.detail.change);
      }
    });

    this._initDatabase();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('db')) {
      this._initDatabase();
    }
  },

  destroy () {
    if (this._changes) {
      this._changes.cancel();
      this._changes = null;
    }
  },

  _initDatabase () {
    if (this._changes) this._changes.cancel();

    if (this.props.db) {
      this._db = dmx.pouchdb.get(this.props.db);
      this._changes = this._db.changes({
        live: true,
        include_docs: true,
        since: 'now',
      }).on('change', (change) => {
        if (dmx.debug) console.debug(`${this.name}:changes:change`, change);
        this.dispatchEvent('change', null, change);
      }).on('error', (err) => {
        if (dmx.debug) console.debug(`${this.name}:changes:error`, err);
        this.dispatchEvent('error', null, err);
      });
    }
  }
  
});
(function () {
  const normalize = (result) => result.rows.map((row) => row.doc);
  const wheres = (wheres) => (rows) => {
    if (wheres) {
      const validate = (row, rule) => {
        if (rule.operator) {
          const a = row[rule.data.column];
          const b = rule.value;

          switch (rule.operator) {
            case "equal":
              return a == b;
            case "not_equal":
              return a != b;
            case "in":
              return b.includes(a);
            case "not_in":
              return !b.includes(a);
            case "less":
              return a < b;
            case "less_or_equal":
              return a <= b;
            case "greater":
              return a > b;
            case "greater_or_equal":
              return a >= b;
            case "between":
              return b[0] <= a <= b[1];
            case "not_between":
              return !(b[0] <= a <= b[1]);
            case "begins_with":
              return String(a).startsWith(String(b));
            case "not_begins_with":
              return !String(a).startsWith(String(b));
            case "contains":
              return String(a).includes(String(b));
            case "not_contains":
              return !String(a).includes(String(b));
            case "ends_with":
              return String(a).endsWith(String(b));
            case "not_ends_with":
              return !String(a).endsWith(String(b));
            case "is_empty":
              return a == null || a == "";
            case "is_not_empty":
              return a != null && a != "";
            case "is_null":
              return a == null;
            case "is_not_null":
              return a != null;
          }
        }

        if (rule.condition && rule.rules.length) {
          for (let _rule of rule.rules) {
            const valid = validate(row, _rule);
            if (!valid && rule.condition == "AND") return false;
            if (valid && rule.condition == "OR") return true;
          }

          return rule.condition == "OR" ? false : true;
        }

        return true;
      };

      return rows.filter((row) => validate(row, wheres));
    }

    return rows;
  };
  const orders = (orders) => (rows) => {
    return rows.sort((a, b) => {
      for (let order of orders) {
        if (a[order.column] == b[order.column]) continue;
        let desc = order.direction && order.direction.toLowerCase() == "desc";
        if (a[order.column] < b[order.column]) {
          return desc ? 1 : -1;
        } else {
          return desc ? -1 : 1;
        }
      }

      return 0;
    });
  };
  const columns = (columns) => (rows) => {
    if (
      Array.isArray(columns) &&
      !(columns.length == 1 && columns[0].column == "*")
    ) {
      return rows.map((doc) => {
        const row = {};

        for (let column of columns) {
          if (column.column == "*") {
            Object.assign(row, doc);
          } else {
            row[column.alias || column.column || column] =
              doc[column.column || column];
          }
        }

        return row;
      });
    }

    return rows;
  };
  const distinct = (distinct) => (rows) => distinct ? [...new Set(rows)] : rows;
  const paged = (offset, limit, includePageInfo) => (rows) => {
    const total = rows.length;
    offset = Number(offset || 0);
    limit = Number(limit || 0);
    rows = rows.slice(offset, limit ? offset + limit : undefined);

    if (includePageInfo) {
      return {
        offset,
        limit,
        total,
        page: {
          offset: {
            first: 0,
            prev: offset - limit > 0 ? offset - limit : 0,
            next: offset + limit < total ? offset + limit : offset,
            last: (Math.ceil(total / limit) - 1) * limit,
          },
          current: Math.floor(offset / limit) + 1,
          total: Math.ceil(total / limit),
        },
        data: rows,
      };
    }

    return rows;
  };

  dmx.Actions({
    "pouchdb.select" (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;
      const table = sql.table.name || sql.table;

      return db
        .allDocs({
          startkey: table + "/",
          endkey: table + "/\uffff",
          include_docs: true,
        })
        .then(normalize)
        .then(wheres(sql.wheres))
        .then(orders(sql.orders))
        .then(columns(sql.columns))
        .then(distinct(sql.distinct))
        .then(paged(sql.offset, sql.limit, false));
    },

    "pouchdb.single": function (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;
      const table = sql.table.name || sql.table;

      return db
        .allDocs({
          startkey: table + "/",
          endkey: table + "/\uffff",
          include_docs: true,
        })
        .then(normalize)
        .then(wheres(sql.wheres))
        .then(orders(sql.orders))
        .then(columns(sql.columns))
        .then(distinct(sql.distinct))
        .then((rows) => rows[0]);
    },

    "pouchdb.paged": function (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;
      const table = sql.table.name || sql.table;

      return db
        .allDocs({
          startkey: table + "/",
          endkey: table + "/\uffff",
          include_docs: true,
        })
        .then(normalize)
        .then(wheres(sql.wheres))
        .then(orders(sql.orders))
        .then(columns(sql.columns))
        .then(distinct(sql.distinct))
        .then(paged(sql.offset, sql.limit, true));
    },

    "pouchdb.insert": function (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;
      const doc = {};

      for (let value of sql.values) {
        doc[value.column] = value.value;
      }

      doc._id = sql.table + "/" + Date.now();

      return db
        .put(doc)
        .then((result) => ({
          affected: result.ok ? 1 : 0,
          identity: result.id,
        }));
    },

    "pouchdb.update": function (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;

      return db
        .allDocs({
          startkey: sql.table + "/",
          endkey: sql.table + "/\uffff",
          include_docs: true,
        })
        .then(normalize)
        .then(wheres(sql.wheres))
        .then((docs) =>
          db.bulkDocs(
            docs.map((doc) => {
              for (let value of sql.values) {
                doc[value.column] = value.value;
              }

              return doc;
            })
          )
        )
        .then((results) => ({
          affected: results.filter((result) => result.ok).length,
        }));
    },

    "pouchdb.delete": function (options) {
      const parsed = this.parse(options);
      const db = dmx.pouchdb.get(parsed.connection);
      const sql = parsed.sql;

      return db
        .allDocs({
          startkey: sql.table + "/",
          endkey: sql.table + "/\uffff",
          include_docs: true,
        })
        .then(normalize)
        .then(wheres(sql.wheres))
        .then((docs) =>
          db.bulkDocs(
            docs.map((doc) => {
              return { _deleted: true, _id: doc._id, _rev: doc._rev };
            })
          )
        )
        .then((results) => ({
          affected: results.filter((result) => result.ok).length,
        }));
    },

    "pouchdb.get": function (options) {
      const { connection, docId, attachments } = this.parse(options);
      const db = dmx.pouchdb.get(connection);

      return db.get(docId, { attachments });
    },

    "pouchdb.getAttachment": function (options) {
      const { connection, docId, name } = this.parse(options);
      const db = dmx.pouchdb.get(connection);

      return db.getAttachment(docId, name).then((blob) => {
        return dmx.fileUtils.blobToDataURL(blob);
      });
    },

    "pouchdb.putAttachment": function (options) {
      const { connection, docId, name, dataURL } = this.parse(options);
      const { data, type } = dmx.fileUtils.parseDataURL(dataURL);
      const db = dmx.pouchdb.get(connection);
      const doc = db.get(docId);

      return db.putAttachment(doc._id, name, doc._rev, data, type);
    },

    "pouchdb.removeAttachment": function (options) {
      const { connection, docId, name } = this.parse(options);
      const db = dmx.pouchdb.get(connection);
      const doc = db.get(docId);

      return db.removeAttachment(doc._id, name, doc._rev);
    },

  });
})();

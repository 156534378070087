dmx.databases = dmx.databases || {};

dmx.pouchdb = {
  
  databases: new Map(),

  setup (name, options = {}) {
    if (!this.databases.has(name)) {
      const db = new PouchDB(name, {
        ...options,
        adapter: 'indexeddb',
      });
      db.setMaxListeners(0);
      this.databases.set(name, db);
      db.info().then((info) => {
        console.log(`PouchDB: ${name}`, info);
      });
      return db;
    }

    return this.databases.get(name);
  },

  get (name) {
    return this.setup(name);
  },

};

dmx.Startup(new Promise((resolve, reject) => {
  // wait for DomContentLoaded, will trigger after all scripts are loaded (including defered scripts)
  document.addEventListener('DOMContentLoaded', resolve);
}).then(() => {
  if (dmx.databases && Object.keys(dmx.databases).length) {
    if (dmx.debug) {
      console.log('dmx.databases:', dmx.databases);
    }

    return Promise.all(Object.keys(dmx.databases).filter(database => {
      return dmx.databases[database].type === 'pouchdb';
    }).map(database => {
      const { version, upgrade, remote, options } = dmx.databases[database];
      const db = dmx.pouchdb.setup(database, { options });

      if (remote) {
        if (dmx.debug) {
          console.log(`${database} replicate from ${remote}`);
        }

        return db.replicate.from(remote).then(() => {
          if (dmx.debug) {
            console.log(`${database} replication done, init sync`);
          }

          db.sync(remote, {
            live: true,
            retry: true,
          }).on('change', (change) => {
            document.dispatchEvent(new CustomEvent('pouchdb:sync', { detail: {database, change }}));
            if (dmx.debug) {
              console.log(`${database} sync change`, change);
            }
          }).on('error', (err) => {
            document.dispatchEvent(new CustomEvent('pouchdb:error', { detail: {database, err }}));
            if (dmx.debug) {
              console.log(`${database} sync error`, err);
            }
          });
        }).catch((err) => {
          console.warn(`Replication error for ${database}:`, err);
        });
      } else {
        if (dmx.debug) {
          console.log(`upgrade ${database} to version ${version}`);
        }

        return db.get('_local/version').then(doc => doc.version).catch(err => 0).then(dbVersion => {
          if (dmx.debug) {
            console.log(`${database} current version ${dbVersion}`);
          }

          return Promise.all(upgrade.map(upgrade => {
            if (dbVersion >= upgrade.toVersion) {
              if (dmx.debug) {
                console.log(`Skipping upgrade for ${database}:`, upgrade);
              }
              return;
            }

            if (dmx.debug) {
              console.log(`Upgrade ${database} to ${upgrade.toVersion}`);
            }

            return db.bulkDocs(upgrade.updates.concat([{
              _id: '_local/version',
              version: upgrade.toVersion,
            }])).then(result => {
              if (dmx.debug) {
                console.log(`Upgrade result for ${database}:`, result);
              }
              return Promise.all(result.filter(res => res.status == 409).map(res => {
                if (dmx.debug) console.log('Conflict', res);
                return db.get(res.id).then(doc => {
                  if (dmx.debug) {
                    console.log('Conflict doc', doc);
                    console.log('Conflict update', upgrade.updates.find(update => update._id == doc._id));
                  }
                  return db.put({
                    ...doc,
                    ...upgrade.updates.find(update => update._id == doc._id),
                    version: upgrade.toVersion,
                  });
                });
              }));
            }).catch(err => {
              console.error(`Upgrade error for ${database}:`, err);
            });
          }));
        });
      }
    }));
  }
}));
dmx.Component('pouchdb-view', {

  initialData: {
    data: [],
    page: 1,
    pages: 1,
    items: 0,
    sort: { on: '', dir: 'asc' },
    has: { first: false, prev: false, next: false, last: false },
  },

  attributes: {
    db: {
      type: String,
      default: '',
    },

    collection: {
      type: String,
      default: '',
    },

    filter: {
      type: String,
      default: '',
    },

    page: {
      type: Number,
      default: 1,
    },

    pagesize: {
      type: Number,
      default: 0,
    },

    sorton: {
      type: String,
      default: '',
    },

    sortdir: {
      type: String,
      default: 'asc',
      enum: ['asc', 'desc'],
    },
  },

  methods: {
    select (page) {
      this._updatePage(+page);
    },

    first () {
      this._updatePage(1);
    },

    prev () {
      this._updatePage(this.data.page - 1);
    },

    next () {
      this._updatePage(this.data.page + 1);
    },

    last () {
      this._updatePage(this.data.pages);
    },

    sort (prop, dir) {
      this.props.sorton = prop;
      this.props.sortdir = (dir && dir.toLowerCase() == 'desc' ? 'desc' : 'asc');
    },
  },

  events: {
    change: Event,
    updated: Event,
    error: Event,
  },

  init () {
    this._docs = [];
    this._items = [];
    this._initDatabase();

    if (this.props.filter) {
      this._updateFilter();
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('db')) {
      this._initDatabase();
    } else if (updatedProps.has('collection')) {
      this._updateDocs();
    } else if (updatedProps.has('filter')) {
      this._updateFilter();
    } else if (updatedProps.has('sorton') || updatedProps.has('sortdir')) {
      this._updateData();
    } else if (updatedProps.has('page') || updatedProps.has('pagesize')) {
      this._updatePage(this.props.page);
    }
  },

  destroy () {
    if (this._changes) {
      this._changes.cancel();
      this._changes = null;
    }

    if (this._filterEffect) {
      this._filterEffect();
      this._filterEffect = null;
    }
  },

  _initDatabase () {
    this._db = null;

    if (this._changes) {
      this._changes.cancel();
    }

    if (this.props.db) {
      this._db = dmx.pouchdb.get(this.props.db);

      this._changes = this._db.changes({
        live: true,
        include_docs: true,
        since: 'now',
        filter: (doc) => doc._id.startsWith(this.props.collection + '/'),
      }).on('change', (change) => {
        if (dmx.debug) console.debug(`${this.name}:changes:change`, change);
        this._updateDocs();
        this.dispatchEvent('change', null, change);
      }).on('error', (err) => {
        if (dmx.debug) console.debug(`${this.name}:changes:error`, err);
        this.dispatchEvent('error', null, err);
      });

      if (this.props.page > 1) {
        this.set('page', this.props.page);
      }
      
      this._updateDocs();
    }
  },

  _updateDocs () {
    if (!this._db) return;

    const { collection } = this.props;

    if (collection) {
      this._db.allDocs({
        startkey: collection + "/",
        endkey: collection + "/\uffff",
        include_docs: true,
      }).then(result => result.rows.map(row => row.doc)).then(docs => {
        if (dmx.debug) console.debug(`${this.name}:query:result`, docs);
        this._docs = docs;
        this._updateData();
        dmx.nextTick(() => this.dispatchEvent("updated"));
      }).catch(err => {
        if (dmx.debug) console.debug(`${this.name}:query:error`, err);
        this.dispatchEvent('error', null, err);
      });
    } else {
      this._docs = [];
      this._updateData();
      dmx.nextTick(() => this.dispatchEvent("updated"));
    }
  },

  _updateFilter () {
    if (this._filterEffect) {
      this._filterEffect();
      this._filterEffect = null;
    }

    if (this.props.filter) {
      this._filterEffect = dmx.effect(() => {
        dmx.parse(this.props.filter, this);
        this._updateData();
      });
    }
  },

  _updateData () {
    this._items = this._docs;
    
    if (this.props.filter) {
      this._items = this._docs.filter(doc => {
        return dmx.parse(this.props.filter, dmx.DataScope(doc, this));
      });
    }

    let { sorton, sortdir, pagesize } = this.props;
    let items = this._items.length;
    let pages = pagesize ? Math.max(1, Math.ceil(items / pagesize)) : 1;
    
    if (sorton) {
      const rev = sortdir === 'desc';
      
      this._items.sort((a, b) => {
        if (rev) {
          return a[sorton] > b[sorton] ? -1 : a[sorton] < b[sorton] ? 1 : 0;
        } else {
          return a[sorton] < b[sorton] ? -1 : a[sorton] > b[sorton] ? 1 : 0;
        }
      });
    }

    this.set({
      pages, items,
    });

    this._updatePage(this.data.page || this.props.page);
  },

  _updatePage (page) {
    const size = this.props.pagesize;
    const pages = this.data.pages;

    page = page < 1 ? 1 : page > pages ? pages : page;

    const offset = (page - 1) * size;

    this.set({
      page: page,
      data: size ? this._items.slice(offset, offset + size) : this._items,
      has: {
        first: page > 1,
        prev: page > 1,
        next: page < pages,
        last: page < pages,
      },
    });
  }

});